import {Component, ViewChild} from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {tap} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {CustomerdetailseditComponent} from '../customerdetailsedit/customerdetailsedit.component';
import {Customer} from '../../models/Customer';
import {CustomersiloeditComponent} from '../customersiloedit/customersiloedit.component';

@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.scss']
})
export class CustomerlistComponent {

  loading = true;
  private dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['gTickID', 'name'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private customerService: CustomerService, private readonly dialog: MatDialog) {
    this.customerService.getCustomers()
      .pipe(tap(() => this.loading = true))
      .subscribe(customers => {
        this.dataSource = new MatTableDataSource(customers);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      });
  }

  openEditCustomerDialog(customer: Customer) {
    this.dialog.open(CustomerdetailseditComponent, {
      width: '1000px',
      data: customer,
      disableClose: true,
      autoFocus: false
    });
  }

  openSiloEditDialog(customer: Customer) {
    this.dialog.open(CustomersiloeditComponent, {
      width: '1000px',
      data: customer.gTickID,
      disableClose: true,
      autoFocus: false
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
