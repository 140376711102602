import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Site } from 'src/app/sites/models/Site';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AllowedSite } from '../../models/AllowedSite';

@Component({
  selector: 'app-user-site-add',
  templateUrl: './user-site-add.component.html',
  styleUrls: ['./user-site-add.component.scss']
})
export class UserSiteAddComponent implements OnInit {
  public addForm: FormGroup;
  public formShouldShow = false;
  @Output() siteRoleAdded: EventEmitter<AllowedSite> = new EventEmitter<AllowedSite>();

  constructor(private fb: FormBuilder, private alertService: AlertService) { }

  ngOnInit() {
    this.addForm = this.fb.group({
      siteId: ['', Validators.required],
      siteName: ['', Validators.required],
      roleName: ['', Validators.required]
    });
  }

  onSiteSelected(site: Site) {
    this.addForm.controls.siteId.setValue(site.siteId);
    this.addForm.controls.siteName.setValue(site.name);
  }

  onLoadFinish(load: boolean) {
    this.formShouldShow = true;
  }

  addSiteRole() {
      if (this.addForm.invalid) {
        this.alertService.alertError('There are errors in the form', 'Error');
        return;
      }
      const allowedSite: AllowedSite = this.addForm.value;
      this.siteRoleAdded.emit(allowedSite);
  }

}
